/*******************************************************************************
 *
 * PLIK ZAWIERAJĄCY MESSAGES MAJĄCE ZASTĘPOWAĆ ZWROTKI Z API PO polu error_code
 * ORAZ HELPERY DO ICH POBIERANIA
 *
 *******************************************************************************/
const MESSAGES = {
    auth: {
        e101: "Nieprawidłowy lub nieważny token rejestracji. Skontaktuj się z Administratorem, aby wysłał do Ciebie nowe zaproszenie.",

        e201: "Nieprawidłowy e-mail lub hasło",
        e202: "Konto zostało zawieszone przez Administrację serwisu",
        e203: "Konto zostało tymczasowo zablokowane z powodu wielu nieudanych prób logowania. Spróbuj ponownie za kilka minut.",

        e301: "Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut",

        e401: "Nieprawidłowy lub nieważny token zmiany hasła.",

        e501: "Nieprawidłowy lub nieważny refresh_token"
    },

    users: {
        e502: "Podany adres e-mail jest już w użyciu",
        e602: "Podany numer telefonu jest już w użyciu",

        e1603: "Nie możesz usunąć konta, które posiada zaplanowaną wizytę lub jest w trakcie wizyty",
        e1604: "Nie możesz usunąć konta, które posiada jakiekolwiek kredyty produktowe",

        e2505: "Wskazany produkt został już przypisany do tego użytkownika",
        e2605: "Nie możesz dezaktywować produktu, który jest przypisany do aktywnej integracji z Mindgram",

        e4003: "Do Mindgram można przypisać tylko produkt typu 'Spotkanie'",
        e4004: "Każdy wybrany produkt do Mindgram musi być wcześniej przypisany do danego terapeuty i być aktywowany.",

        e4104: "Każdy wybrany produkt do Mindgram musi być wcześniej przypisany do danego terapeuty i być aktywowany.",

        e4203: "Istnieje chociaż jedna zaplanowana lub trwająca wizyta pochodząca z Mindgram przypisana do tego terapeuty.",

        e6002: "Użytkownik jest już powiązany z Google Workspace",
        e6003: "Nie odnaleziono użytkownika Google Workspace o podanym adresie e-mail",
        e6098: "Błąd połączenia z Google Workspace. Spróbuj ponownie później",

        e6102: "Użytkownik nie jest powiązany z Google Workspace",
        e6103: "Dopóki użytkownik jest przypisany do obsługi rekrutacji terapeutów (etap FVA) nie można odłączyć go od Google Workspace"
    },

    me: {
        e301: "Podano nieprawidłowe obecne hasło",

        e402: "Rozdzielczość przesłanego obrazu jest zbyt niska",
        e403: "Rozdzielczość przesłanego obrazu jest zbyt wysoka"
    },

    quizzes: {
        e402: "Istnieje już inny Quiz o takim adresie URL"
    },

    quiz_boards: {
        e502: "Nie można usunąć planszy będącej w użyciu"
    },

    mediamanager_folders: {
        e202: "W aktualnym katalogu istnieje już folder o takiej nazwie",
        e402: "W aktualnym katalogu istnieje już folder o takiej nazwie"
    },

    wordpress_blog_posts: {
        e501: "Trwa już synchronizacja wpisów",
        e601: "Trwa już synchronizacja wpisów",
        e701: "Nie można usunąć wpisów, kiedy trwa synchronizacja"
    },

    discount_codes: {
        e231: "Taki kod zniżkowy już istnieje",
        e431: "Kod o takim polu 'Kod' już istnieje"
    },

    user_incomes: {
        e302: "Kwota Wpływu może być edytowana tylko jeżeli nie jest on częścią żadnego rozliczenia lub rozliczenie, w którym się zawiera nie zostało jeszcze zaakceptowane."
    },

    refunds: {
        einsufficient_amount_value: "Podana kwota przekracza pozostałe środki w zamówieniu",
        epending_refund_conflict:
            "Poczekaj na potwierdzenie poprzednio utworzonego zwrotu przed dodaniem kolejnego."
    },

    payment_intents: {
        e402: "Faktura została już wystawiona do tej płatności",
        e403: "Wskazana płatność nie jest opłacona",
        e404: "Nie można wystawić faktury do produktu, który został częściowo lub całkowicie zwrócony",
        e405: "Błąd wystawienia faktury"
    },

    appointments: {
        e410: "Wybrany termin wizyty nie jest już dostępny",
        e606: "Wybrany termin wizyty nie jest już dostępny",

        e1003: "To spotkanie zostało już opłacone. Odśwież stronę, jeżeli nie widzisz zmian w jego statusie."
    },

    products: {
        e1303: "Produkt został już przypisany do przynajmniej jednego terapeuty, który posiada strategię dostępności - edycja pola 'Domyślny czas trwania' nie jest już możliwa.",
        e1304: "Element w tym języku został już dodany.",
        e1305: "Nie znaleziono produktu w tym wariancie językowym",
        e1306: "Wybrany wariant językowy wskazuje na inny język, niż przypisany do niego produkt"
    },

    user_settlements: {
        e503: "Nie można zaakceptować faktury dla rozliczenia zerowego"
    },

    services: {
        e202: "Użytkownik nie jest powiązany z Timerise.",
        e502: "Aby można było usunąć usługę, nie może ona być dowiązana do żadnego innego modułu w aplikacji"
    },

    therapist_job_applications: {
        e2200: "Nieprawidłowe dane wejściowe",
        e2201: "Nie znaleziono obiektu TJA",
        e2202: "Żądany obiekt TJA musi być na statusie in_progress oraz etapie fva_qualified",
        e2203: "Błędna konfiguracja w GlobalSettings",
        e2204: "Przesłany agent nie jest skonfigurowany w GlobalSettings do obsługi rekrutacji na tym etapie",
        e2205: "Przesłany agent nie jest połączony z Google Workspace",
        e2206: "Przesłany slot (bez względu na jego rodzaj) nie może zostać zarezerwowany",
        e2207: "Błąd komunikacji z Google Calendar",
        e2300: "Nieprawidłowe dane wejściowe",
        e2301: "Nie znaleziono obiektu TJA",
        e2302: "Żądany obiekt TJA musi być na statusie in_progress oraz etapie fva_scheduled",
        e2303: "Jako kandydat nie możesz przełożyć terminu na mniej niż 24h od daty rozpoczęcia,",
        e2304: "Jako kandydat nie możesz przełożyć terminu więcej niż raz na dobę",
        e2305: "Błędna konfiguracja w GlobalSettings",
        e2306: "Przesłany agent nie jest skonfigurowany w GlobalSettings do obsługi rekrutacji na tym etapie",
        e2307: "Przesłany agent nie jest połączony z Google Workspace",
        e2308: "Przesłany slot (bez względu na jego rodzaj) nie może zostać zarezerwowany",
        e2605: "Wszystkie produkty zdefiniowane w umowie stałej muszą być dodane w sekcji produktów"
    },

    global_settings: {
        e304: "Nie wszyscy agenci są połączeni z Google Workspace."
    }
};

function getNestedObjProperty(obj, property) {
    let work_obj = obj;
    const splitted_property = property.split(".");

    for (let i = 0; i < splitted_property.length; i++) {
        if (typeof work_obj != "object" || work_obj[splitted_property[i]] == undefined) {
            return null;
        }

        work_obj = work_obj[splitted_property[i]];
    }

    return work_obj;
}

export function messageExists(path) {
    if (!path || path == "") return false;
    return getNestedObjProperty(MESSAGES, path) !== null;
}

export function getMessage(path) {
    if (!path || path == "") return "";
    const t = getNestedObjProperty(MESSAGES, path);
    return t === null ? path : t;
}
